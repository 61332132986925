import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Tooltip,
  Chip,
  IconButton,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ArrowForwardIosRounded,
  GridOnRounded,
  ImageRounded,
  PictureAsPdfRounded,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: "7px !important",
  },
  card: {
    borderRadius: 12,
    border: `1px solid ${theme.palette.divider}`,
  },
  activeCard: {
    border: `1px solid ${theme.palette.accent}`,
    background: theme.palette.paper,
  },
}));

function AnswerEntity({ source, answer, handleEntityChange = () => {} }) {
  const { classes } = useStyles();
  const { files } = useSelector((state) => state.files);
  const { content_object } = answer;
  const entity = content_object["entity"];

  let entityExists = entity && entity["file_id"] && entity["title"];
  let file = null;
  if (entityExists) {
    file = files.find((f) => f["id"] === entity["file_id"]);
  }

  const isSelected = source && source["created_at"] === answer["created_at"];

  return (
    <Card
      elevation={1}
      onClick={() =>
        entityExists
          ? handleEntityChange({
              ...entity,
              created_at: answer["created_at"],
            })
          : null
      }
      className={`${classes.card} ${isSelected ? classes.activeCard : null}`}>
      <CardActionArea sx={{ pointerEvents: entityExists ? "auto" : "none" }}>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            mr={1.5}
            width={"calc(100% - 36px)"}>
            {entityExists && (
              <IconButton
                sx={{
                  background: " rgba(14, 157, 88, 0.08)",
                  marginRight: 1,
                }}>
                <PictureAsPdfRounded sx={{ color: "#E94748" }} />
              </IconButton>
            )}
            <Box width={"calc(100% - 40px)"}>
              <Typography fontWeight={500} variant="body2">
                {entityExists
                  ? entity["title"]
                  : "Sorry, could not find the document"}
              </Typography>
              <Box mb={1.5} />
              {file && (
                <Tooltip title={file["name"]}>
                  <Chip
                    label={
                      <Typography variant="caption" noWrap>
                        {file["name"]}
                      </Typography>
                    }
                    size="small"
                    icon={
                      <img
                        src={getFileIconImage(file["file_type"])}
                        alt={file["name"]}
                        width={16}
                        height={16}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
          {entityExists && (
            <IconButton size="small">
              <ArrowForwardIosRounded fontSize="small" />
            </IconButton>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
    // <Box maxWidth={"100%"}>
    //   <Card
    //     elevation={1}
    //     onClick={() =>
    //       entityExists
    //         ? handleEntityChange({
    //             ...entity,
    //             created_at: answer["created_at"],
    //           })
    //         : null
    //     }
    //     className={`${classes.card} ${isSelected ? classes.activeCard : null}`}>
    //     <CardActionArea sx={{ pointerEvents: entityExists ? "auto" : "none" }}>
    //       <CardContent
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //         }}>
    //         <Box
    //           display={"flex"}
    //           alignItems={"center"}
    //           mr={1.5}
    //           width={"calc(100% - 36px)"}
    //           overflow={"scroll"}>
    //           {entityExists && (
    //             <IconButton
    //               sx={{
    //                 background: " rgba(14, 157, 88, 0.08)",
    //                 marginRight: 1,
    //               }}>
    //               {/* {entityType === "image" ? (
    //                 <ImageRounded sx={{ color: "#0E9D58" }} />
    //               ) : (
    //                 <GridOnRounded sx={{ color: "#0E9D58" }} />
    //               )} */}
    //               <PictureAsPdfRounded sx={{ color: "#E94748" }} />
    //             </IconButton>
    //           )}
    //           <Box>
    //             <Typography fontWeight={500} variant="body2">
    //               {entityExists
    //                 ? entity["title"]
    //                 : "Sorry, could not find the document"}
    //             </Typography>
    //             <Box mb={1.5} />
    //             {file && (
    //               <Tooltip title={file["name"]}>
    //                 <Chip
    //                   // sx={{ maxWidth: 100 }}
    //                   label={
    //                     <Typography variant="caption" noWrap>
    //                       {file["name"]}
    //                     </Typography>
    //                   }
    //                   size="small"
    //                   icon={
    //                     <img
    //                       src={getFileIconImage(file["file_type"])}
    //                       alt={file["name"]}
    //                       width={16}
    //                       height={16}
    //                     />
    //                   }
    //                 />
    //               </Tooltip>
    //             )}
    //           </Box>
    //         </Box>
    //         {entityExists && (
    //           <IconButton size="small">
    //             <ArrowForwardIosRounded fontSize="small" />
    //           </IconButton>
    //         )}
    //       </CardContent>
    //     </CardActionArea>
    //   </Card>
    // </Box>
  );
}

export default AnswerEntity;
