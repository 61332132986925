import React, { useEffect, useState } from "react";
import Header from "./Header";
import Results from "./Results";
import EmptyFilesScreen from "./EmptyFilesScreen";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import useScrollHeader from "../../../hooks/useScrollHeader";

function FilesView({
  files,
  isFetched,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { selectedDomain } = useSelector((state) => state.domain);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const { containerRef, showHeader } = useScrollHeader();

  useEffect(() => {
    setFilteredFiles(files);
  }, [files]);

  return (
    <>
      <Header showHeader={showHeader} />
      <Box ref={containerRef} sx={{ flex: 1, overflow: "auto" }}>
        {isFetched && (
          <Results
            files={filteredFiles}
            selectedDomain={selectedDomain}
            handleSelectFile={handleSelectFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        )}
      </Box>
    </>
  );
}

export default FilesView;
