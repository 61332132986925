import React, { useState } from "react";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import {
  Typography,
  Box,
  Button,
  FormHelperText,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { sendMagicLink } from "../../core/repo/accountRepo";
import Logo from "../../components/Logo";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { EMAIL_VERIFICATION_URL } from "../../utils/routes";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  container: {
    height: "100vh",
    height: "100svh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email is required."),
});

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  async function handleSendEmailMagicLink(values) {
    setIsLoading(true);
    try {
      const { email } = values;
      await dispatch(sendMagicLink(email));
      enqueueSnackbar("Verification link has been sent to your email.", {
        variant: "success",
      });
      history.push({
        pathname: EMAIL_VERIFICATION_URL,
        search: "?" + new URLSearchParams({ email: email }).toString(),
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to send verfication link to your email.", {
        variant: "error",
      });
    }
    setIsLoading(false);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSendEmailMagicLink,
  });

  return (
    <Page className={classes.root} title="Login">
      <Box className={classes.container}>
        <Box
          p={{ md: 5, xs: 3 }}
          boxShadow={"0px 4px 8px 3px rgba(63, 81, 181, 0.12)"}
          borderRadius={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Logo />
            <Box mb={1} />
            <Typography variant="h5">Welcome to Raven AI</Typography>
            <Box mb={3} />
            <Typography variant="subtitle2" textAlign={"center"}>
              Enter your email
            </Typography>
            <Box mb={2} />
            <form style={{ width: "100%" }}>
              <TextField
                label="Email"
                name="email"
                placeholder="example@email.com"
                sx={{ width: 300 }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                InputProps={{ style: { borderRadius: 8 } }}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText error sx={{ ml: 1 }}>
                  {formik.errors.email}
                </FormHelperText>
              )}
              <Box mb={3} />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isLoading}
                onClick={formik.handleSubmit}
                sx={{
                  background:
                    "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%) !important",
                }}>
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={26.5} />
                ) : (
                  "Continue"
                )}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default Login;
