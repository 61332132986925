import React from "react";
import { Box, Typography } from "@mui/material";

function Header({ showHeader }) {
  return (
    <Box
      p={1.5}
      px={{ xs: 2, sm: 3 }}
      visibility={showHeader ? "visible" : "hidden"}>
      <Typography variant="h6">Saved Messages</Typography>
    </Box>
  );
}

export default Header;
