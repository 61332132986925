import { models } from "../../utils/utils";

export function getAccountDetails() {
  const str = localStorage.getItem("account");
  let account = "";
  if (str) {
    account = JSON.parse(str);
  }

  return account;
}

export function getUserDetails() {
  const str = localStorage.getItem("user");
  let user = "";
  if (str) {
    user = JSON.parse(str);
  }

  return user;
}

export function setAIModel(model) {
  localStorage.setItem("model", model);
}

export function getAIModel() {
  const model = localStorage.getItem("model");
  return model || models[0];
}

export function setIsDebugModeOn(debugMode) {
  localStorage.setItem("debug_mode", debugMode);
}

export function getIsDebugModeOn() {
  const isDebugModeOn = localStorage.getItem("debug_mode");
  if (isDebugModeOn && isDebugModeOn === "false") {
    return false;
  }
  return true;
}

export function setAccount(account) {
  localStorage.setItem("account", JSON.stringify(account));
}

export function clearAccountDetails() {
  localStorage.setItem("account", null);
}

export function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function clearLocalStorage() {
  localStorage.setItem("user", null);
  localStorage.setItem("account", null);
  localStorage.setItem("file_grid_view", null);
  localStorage.setItem("debug_mode", null);
  localStorage.setItem("model", null);
  localStorage.setItem("chat_mode", null);
  localStorage.setItem("selected_files", null);
  localStorage.setItem("selected_data_analysis_file", null);
}

export function setFileGridViewLocalStorage(value) {
  localStorage.setItem("file_grid_view", value);
}

export function getFileGridViewLocalStorage() {
  const fileGridView = localStorage.getItem("file_grid_view");
  if (fileGridView && fileGridView === "false") {
    return false;
  }
  return true;
}

export function setChatAgentLocalStorage(chatAgent) {
  localStorage.setItem("chat_agent", chatAgent);
}

export function getChatAgentLocalStorage() {
  return localStorage.getItem("chat_agent");
}

export function setChatModeLocalStorage(chatMode) {
  localStorage.setItem("chat_mode", chatMode);
}

export function getChatModeLocalStorage() {
  const chatMode = localStorage.getItem("chat_mode");
  if (!chatMode || chatMode === "null") {
    return "general_qna";
  }
  return chatMode;
}

export function setSelectedFilesLocalStorage(selectedFiles) {
  localStorage.setItem("selected_files", JSON.stringify(selectedFiles));
}

export function getSelectedFilesLocalStorage() {
  const selectedFiles = localStorage.getItem("selected_files");
  if (!selectedFiles || selectedFiles === "null") {
    return [];
  }
  try {
    const parsedSelectedFiles = JSON.parse(selectedFiles);
    return parsedSelectedFiles;
  } catch (err) {
    return [];
  }
  return [];
}

export function setSelectedDataAnalysisFileLocalStorage(
  selectedDataAnalysisFile
) {
  localStorage.setItem(
    "selected_data_analysis_file",
    JSON.stringify(selectedDataAnalysisFile)
  );
}

export function getSelectedDataAnalysisFileLocalStorage() {
  const selectedDataAnalysisFile = localStorage.getItem(
    "selected_data_analysis_file"
  );
  if (!selectedDataAnalysisFile || selectedDataAnalysisFile === "null") {
    return null;
  }
  try {
    const parsedSelectedDataAnalysisFile = JSON.parse(selectedDataAnalysisFile);
    return parsedSelectedDataAnalysisFile;
  } catch (err) {
    return null;
  }
  return null;
}

export function setSelectedDomainLocalStorage(selectedDomain) {
  localStorage.setItem("selected_domain", JSON.stringify(selectedDomain));
}

export function getSelectedDomainLocalStorage() {
  const selectedDomain = localStorage.getItem("selected_domain");
  if (!selectedDomain || selectedDomain === "null") {
    return null;
  }
  try {
    const parsedSelectedDomain = JSON.parse(selectedDomain);
    return parsedSelectedDomain;
  } catch (err) {
    return null;
  }
  return null;
}
