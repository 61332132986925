import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Logo from "../../../components/Logo";
import { makeStyles } from "tss-react/mui";
import { MenuRounded } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.light,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function Header({ expanded, toggleExpand }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      p={1.5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={expanded ? "space-between" : "center"}>
      {isXS && <Logo variant="small" />}
      {expanded && (
        <Box display={"flex"} alignItems={"center"}>
          <Logo variant="small" />
          <Box mr={1} />
          <Typography fontSize={15} fontWeight={600}>
            <span className={classes.gradientText}>Raven AI</span>
          </Typography>
        </Box>
      )}
      {!isXS && (
        <IconButton onClick={() => toggleExpand((prev) => !prev)}>
          <MenuRounded />
        </IconButton>
      )}
    </Box>
  );
}

export default Header;
