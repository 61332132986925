import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookmarkedMessages } from "../../core/repo/sessionRepo";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import Loader from "../../components/Loader";
import { Box, Typography, Grid } from "@mui/material";
import Header from "./Header";
import useScrollHeader from "../../hooks/useScrollHeader";
import { formQuestionAnswerPairs } from "../AskAI/Messages";
import ChatModeTabs from "./ChatModeTabs";
import { chatModes } from "../DashboardLayout/Sidebar/NewSessionButton";
import SourceDialog from "../AskAI/components/SourceDialog";
import EntityDialog from "../AskAI/ChecklistDiagram/components/EntityDialog";
import TabularDialog from "../AskAI/DataAnalysis/Messages/SourceDialog";
import Results from "../AskAI/Messages/Results";
import { useLocation, useHistory } from "react-router-dom";
import { BOOKMARKS_URL } from "../../utils/routes";
import NoBookmarks from "./NoBookmarks";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function Bookmarks() {
  const location = useLocation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [bookmarkedMessages, setBookmarkedMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { containerRef, showHeader } = useScrollHeader(150);
  const [selectedChatMode, setSelectedChatMode] = useState(chatModes[0]["key"]);
  const messageRefs = useRef({});
  const [source, setSource] = useState(null);
  const [entity, setEntity] = useState(null);
  const [tabular, setTabular] = useState(null);
  const messageId = new URLSearchParams(location.search).get("messageId");

  const fetchBookmarkedMessages = async () => {
    setIsLoading(true);
    setIsFetched(false);
    try {
      setBookmarkedMessages([]);
      const response = await dispatch(getBookmarkedMessages());
      const listOfQuestionAndAnswerPairs = formQuestionAnswerPairs(
        response["messages"] || []
      );
      setBookmarkedMessages(listOfQuestionAndAnswerPairs);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setIsFetched(true);
  };

  useEffect(() => {
    fetchBookmarkedMessages();
  }, []);

  useEffect(() => {
    if (!isLoading && messageId && messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [bookmarkedMessages]);

  const handleSourceChange = (source) => {
    setSource(source);
    setEntity(null);
    setTabular(null);
  };

  const handleEntityChange = (entity) => {
    setEntity(entity);
    setSource(null);
    setTabular(null);
  };

  const handleTabularChange = (tabular) => {
    setTabular(tabular);
    setSource(null);
    setEntity(null);
  };

  return (
    <Page className={classes.root} title={"Saved Messages"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header showHeader={showHeader} />
        {/* {showHeader && (
          <>
            <ChatModeTabs
              selectedChatMode={selectedChatMode}
              handleSelectedChatModeChange={handleSelectedChatModeChange}
            />
            <Box mb={3} />
          </>
        )} */}
        <Box flex={1} overflow={"auto"}>
          <Grid container height={"100%"}>
            <Grid
              item
              sm={source ? 7 : entity || tabular ? 4 : 12}
              height={"100%"}
              width={"100%"}
              position={"relative"}>
              <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                <Box ref={containerRef} flex={1} overflow={"auto"}>
                  <Box
                    p={{ xs: 2, sm: 3 }}
                    maxWidth={900}
                    margin={"0 auto"}
                    display="flex"
                    flexDirection="column"
                    flex={1}>
                    <Box mb={3}>
                      <Typography variant="h4">
                        <span className={classes.gradientText}>
                          Saved Messages
                        </span>
                      </Typography>
                    </Box>
                    {/* <ChatModeTabs
                      selectedChatMode={selectedChatMode}
                      handleSelectedChatModeChange={
                        handleSelectedChatModeChange
                      }
                    />
                    <Box mb={3} /> */}
                    {bookmarkedMessages.length === 0 && isFetched ? (
                      <NoBookmarks />
                    ) : (
                      <Results
                        source={source}
                        entity={entity}
                        tabular={tabular}
                        messages={bookmarkedMessages}
                        messageRefs={messageRefs}
                        messagesRef={containerRef}
                        handleEntityChange={handleEntityChange}
                        handleSourceChange={handleSourceChange}
                        handleTabularChange={handleTabularChange}
                        showToolbar={false}
                      />
                    )}
                    <Box mb={{ xs: 4, sm: 6 }} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            {source && (
              <SourceDialog
                source={source}
                handleSourceChange={handleSourceChange}
              />
            )}
            {entity && (
              <EntityDialog
                entity={entity}
                handleEntityChange={handleEntityChange}
              />
            )}
            {tabular && (
              <TabularDialog
                fileId={tabular["file_id"]}
                source={tabular}
                handleSourceChange={handleTabularChange}
              />
            )}
          </Grid>
        </Box>
        {isLoading && <Loader />}
      </Box>
    </Page>
  );
}

export default Bookmarks;
