import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Circle } from "@mui/icons-material";
import {
  getAccountDetails,
  getUserDetails,
} from "../../../../core/storage/localStorage";

function Usage({ isLastMessage }) {
  const theme = useTheme();
  const account = getAccountDetails();
  const user = getUserDetails();
  const [numOfMessages, setNumOfMessages] = useState(
    user ? user["num_of_messages"] : 0
  );

  useEffect(() => {
    const handleStorage = () => {
      const user = getUserDetails();
      setNumOfMessages(user ? user["num_of_messages"] : 0);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  return (
    !(account && account["is_org"]) &&
    user &&
    user["num_of_messages"] > 46 &&
    isLastMessage && (
      <Box
        display={"flex"}
        alignItems={"center"}
        borderRadius={1}
        bgcolor={theme.palette.background.dark}
        py={1}
        px={1.25}
        ml={1}>
        <Circle color="success" sx={{ fontSize: 8 }} />
        <Box mr={0.5} />
        <Typography variant="caption">{numOfMessages} of 50</Typography>
      </Box>
    )
  );
}

export default Usage;
