export const LOGOUT_SUCCESS = "@account/logout-success";
export const TOGGLE_ASSISTANT_MODE_SUCCESS =
  "@account/toggle-assistant-mode-success";

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function toggleAssistantModeSuccess(assistantMode) {
  return {
    type: TOGGLE_ASSISTANT_MODE_SUCCESS,
    payload: assistantMode,
  };
}
