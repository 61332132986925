import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  IconButton,
  useTheme,
  Chip,
  Tooltip,
  Card,
  CardContent,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ArrowOutward,
  ErrorOutlineRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  PublicRounded,
  Replay,
  Check,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getFileIconImage } from "../../Files/FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme) => ({
  text: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    // minHeight: 40,
  },
  citationCard: {
    border: `1px solid ${theme.palette.divider}`,
    marginRight: 16,
    marginBottom: 4,
    borderRadius: 12,
    minWidth: 300,
    maxWidth: 300,
  },
  activeCard: {
    border: `1px solid ${theme.palette.accent}`,
    background: theme.palette.paper,
  },
  tabContainer: {
    width: "100%",
  },
  carouselBtn: {
    color: theme.palette.text.secondary,
    background: "#FFFFFF",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
  },
  label: {
    display: "flex",
    paddingLeft: 8,
    paddingRight: 8,
  },
  icon: {
    margin: "0px 5px 0px -6px !important",
  },
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
}));

function Sources({ source, answer, handleSourceChange }) {
  const citations = answer["content_object"]["citations"] || [];
  const theme = useTheme();
  const { classes } = useStyles();
  const [areSourcesOpen, setAreSourcesOpen] = useState(false);
  const entity = answer["content_object"]["entity"];
  let entityExists = entity && entity["file_id"] && entity["title"];

  const reloadPage = () => {
    window.location.reload();
  };

  const toggleSources = () => {
    setAreSourcesOpen((prev) => !prev);
  };

  if (!answer["content_object"]["answer_found"]) {
    return null;
  }

  if (answer["error"] && answer["error"] === "metadata_api_fail") {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <ErrorOutlineRounded sx={{ color: theme.palette.error.main }} />
        <Box mr={1} />
        <Typography variant="subtitle2" color={"textSecondary"}>
          Error loading sources.
        </Typography>
        <Box mr={1} />
        <IconButton size="small" onClick={reloadPage}>
          <Replay fontSize="small" />
        </IconButton>
      </Box>
    );
  }

  if (citations.length === 0 && !entityExists) {
    return <GeneralDomain />;
  }

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        {citations.length > 0 && (
          <Chip
            variant={areSourcesOpen ? "filled" : "outlined"}
            classes={{ icon: classes.icon }}
            label={"Sources"}
            icon={
              areSourcesOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />
            }
            onClick={toggleSources}
            sx={{
              // background: areSourcesOpen ? "#E8EAF6" : "transparent",
              flexDirection: "row-reverse",
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          />
        )}
      </Box>
      {areSourcesOpen && (
        <SourcesList
          source={source}
          answer={answer}
          handleSourceChange={handleSourceChange}
        />
      )}
    </>
  );
}

function SourcesList({ source, answer, handleSourceChange }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { files } = useSelector((state) => state.files);
  const citations = answer["content_object"]["citations"] || [];

  return (
    <Box pt={2}>
      <Tabs
        sx={{ position: "relative" }}
        scrollButtons="auto"
        variant="scrollable"
        ScrollButtonComponent={(props) => {
          const { direction, disabled } = props;
          if (direction === "left" && !disabled) {
            return (
              <Box
                height={101.31}
                width={78}
                display={"flex"}
                alignItems={"center"}
                position={"absolute"}
                left={0}
                zIndex={1}
                sx={{
                  background:
                    "linear-gradient(268deg, rgba(255, 255, 255, 0.00) 1.31%, #FFF 79.43%)",
                }}>
                <IconButton
                  {...props}
                  sx={{ marginLeft: 0.25 }}
                  className={classes.carouselBtn}
                  disableRipple>
                  <ArrowBackIosRounded sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            );
          } else if (direction === "right" && !disabled) {
            return (
              <Box
                height={101.31}
                width={78}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                position={"absolute"}
                right={0}
                sx={{
                  background:
                    "linear-gradient(268deg, #FFF 19.96%, rgba(255, 255, 255, 0.00) 98.08%)",
                }}>
                <IconButton
                  {...props}
                  sx={{ marginRight: 0.25 }}
                  className={classes.carouselBtn}
                  disableRipple>
                  <ArrowForwardIosRounded sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            );
          } else {
            return null;
          }
        }}>
        {citations.map((citation, i) => {
          let { section, file_id, sub_title } = citation;
          const file = files.find((f) => f["id"] === file_id);
          const isSelected =
            source &&
            source["created_at"] === answer["created_at"] &&
            source["id"] === citation["id"] &&
            source["section"] === citation["section"];
          return (
            <Card
              elevation={1}
              key={i}
              className={`${classes.citationCard} ${
                isSelected ? classes.activeCard : null
              }`}
              onClick={() =>
                handleSourceChange({
                  ...citation,
                  created_at: answer["created_at"],
                })
              }>
              <CardActionArea sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <Box
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}>
                    <Box flex={1}>
                      <Typography variant="body2" className={classes.text}>
                        {section || "."}
                      </Typography>
                    </Box>
                    <Box flex={1} mt={0.5}>
                      <Typography
                        variant="caption"
                        color={"textSecondary"}
                        className={classes.text}>
                        {sub_title || " "}
                      </Typography>
                    </Box>
                    <Box mb={1.5} />
                    {file && (
                      <Box>
                        <Tooltip title={file["name"]}>
                          <Chip
                            sx={{ paddingLeft: 0.5 }}
                            label={
                              <Typography variant="caption">
                                {file["name"]}
                              </Typography>
                            }
                            size="small"
                            icon={
                              <img
                                src={getFileIconImage(file["file_type"])}
                                alt={file["name"]}
                                width={16}
                                height={16}
                              />
                            }
                            deleteIcon={<ArrowOutward />}
                            onDelete={() => {}}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Tabs>
    </Box>
  );
}

function TabularDataSteps({ steps }) {
  const { classes } = useStyles();

  const processedSteps = steps.map((step) => {
    const [title, value] = step.split(":", 2);
    return { title: title.trim(), value: value.trim() };
  });

  return (
    <Box pb={4} pl={6}>
      <Timeline sx={{ padding: 0, margin: 0 }}>
        {processedSteps.map((step, i) => {
          const { title, value } = step;
          return (
            <TimelineItem
              key={i}
              classes={{
                missingOppositeContent: classes.missingOppositeContent,
              }}
              sx={{ minHeight: i === processedSteps.length - 1 ? 0 : 70 }}>
              <TimelineSeparator>
                <Tooltip title={title}>
                  <TimelineDot sx={{ padding: 0, marginTop: "9px" }}>
                    <Check sx={{ fontSize: 10 }} />
                  </TimelineDot>
                </Tooltip>
                {i !== processedSteps.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ width: "100%" }}>
                <Typography variant="subtitle2" color={"textSecondary"}>
                  {value}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
}

function GeneralDomain() {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <PublicRounded sx={{ color: theme.palette.action.disabled }} />
        <Box mr={1} />
        <Typography
          variant="subtitle2"
          color={"textSecondary"}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handleOpenDialog}>
          Answered from General Domain
        </Typography>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>General Domain Information</DialogTitle>
        <DialogContent>
          <Typography>
            This information is generated from a general domain and may not be
            verified with trusted sources.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Sources;
