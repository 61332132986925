import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllSessions, createSession } from "../../core/repo/sessionRepo";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";

function AssistantRedirect() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchAllSessions() {
    setIsLoading(true);
    try {
      const sessions = await dispatch(getAllSessions());
      if (sessions.length > 0) {
        history.push(`/assistant/${sessions[0].id}`);
      } else {
        const session = await dispatch(createSession());
        history.push(`/assistant/${session.id}`);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAllSessions();
  }, []);

  return <>{isLoading && <Loader />}</>;
}

export default AssistantRedirect;
