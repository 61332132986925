import { combineReducers } from "redux";
import domainReducer from "./domainReducer";
import filesReducer from "./filesReducer";
import sessionReducer from "./sessionReducer";
import accountReducer from "./accountReducer";

const rootReducer = combineReducers({
  domain: domainReducer,
  files: filesReducer,
  session: sessionReducer,
  account: accountReducer,
});

export default rootReducer;
