import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import RenderMarkdown from "./RenderMarkdown";

function StreamAnswer({ answer }) {
  const { content_object } = answer;
  const [expandedSections, setExpandedSections] = useState({});

  const handleAccordionChange = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const extractContent = (text) => {
    if (!text)
      return { preContent: null, intermediateContent: [], finalContent: null };

    // Find the index of the first tag
    const firstTagIndex = text.indexOf("<");

    // Extract pre-content if it exists
    const preContent =
      firstTagIndex > 0 ? text.slice(0, firstTagIndex).trim() : null;

    // Extract sections between < > tags
    const sections = text.match(/<([^>]+)>([\s\S]*?)<\/\1>/g);

    if (!sections)
      return { preContent, intermediateContent: [], finalContent: text };

    // Process each section
    const intermediateContent = [];
    let finalContent = null;

    sections.forEach((section) => {
      const sectionMatch = section.match(/<([^>]+)>([\s\S]*?)<\/\1>/);
      if (!sectionMatch) return;

      const [, sectionName, content] = sectionMatch;

      if (sectionName.toLowerCase() === "final") {
        // Extract only the content within the final tags
        finalContent = content.trim();
      } else {
        intermediateContent.push({
          name: sectionName,
          content: content.trim(),
        });
      }
    });

    // If no final tag was found, use the original text
    return {
      preContent,
      intermediateContent,
      finalContent: finalContent || text,
    };
  };

  const { preContent, intermediateContent, finalContent } = extractContent(
    content_object["text"]
  );

  return (
    <Box>
      {answer["error"] && answer["error"] === "stream_api_fail" ? (
        <Typography>
          There was a problem generating this answer. Please try again.
        </Typography>
      ) : (
        content_object["text"] && (
          <>
            {preContent && (
              <Box mb={1}>
                <RenderMarkdown message={preContent} />
              </Box>
            )}
            {intermediateContent.length > 0 && (
              <Box mb={1}>
                {intermediateContent.map((section, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedSections[section.name] || false}
                    onChange={() => handleAccordionChange(section.name)}
                    elevation={0}
                    disableGutters
                    sx={{
                      backgroundColor: "rgba(145, 158, 171, 0.08)",
                      "&:before": { display: "none" },
                      "&:not(:last-child)": { mb: 1 },
                      boxShadow: "none",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 2,
                      "&:first-of-type": {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                      },
                      "&:last-of-type": {
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                      },
                      "&:hover": {
                        backgroundColor: "rgba(145, 158, 171, 0.12)",
                      },
                      "& .MuiAccordion-region": {
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                      },
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      sx={{
                        minHeight: 48,
                        "& .MuiAccordionSummary-content": {
                          margin: "12px 0",
                        },
                      }}>
                      <Typography variant="body2" color="text.secondary">
                        {section.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 2,
                        borderTop: "1px solid",
                        borderColor: "divider",
                      }}>
                      <RenderMarkdown message={section.content} />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
            <RenderMarkdown message={finalContent} />
          </>
        )
      )}
    </Box>
  );
}

export default StreamAnswer;
