import React from "react";
import { Box } from "@mui/material";
import AnswerEntity from "./AnswerEntity";
import StreamAnswer from "./StreamAnswer";
import AnswerAvatar from "./AnswerAvatar";
import TabularDataAnswer from "./TabularDataAnswer";

function Answer({
  answer,
  entity,
  tabular,
  handleEntityChange,
  handleTabularChange,
}) {
  const answerType = answer["content_object"]["type"];

  function AnswerBasedOnType() {
    if (answerType === "entity") {
      return (
        <AnswerEntity
          source={entity}
          answer={answer}
          handleEntityChange={handleEntityChange}
        />
      );
    } else if (answerType === "tabular_data") {
      return (
        <TabularDataAnswer
          source={tabular}
          answer={answer}
          handleSourceChange={handleTabularChange}
        />
      );
    }
    return <StreamAnswer answer={answer} />;
  }

  return (
    <Box display={"flex"} alignItems={"start"}>
      <AnswerAvatar answerType={answerType} />
      <AnswerBasedOnType />
    </Box>
  );
}

export default Answer;
