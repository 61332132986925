import React, { useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  Grid,
} from "@mui/material";
import { CloseRounded, Fullscreen, Print } from "@mui/icons-material";
import PDFView from "../../../components/PDFView";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import FullViewPDF from "./FullViewPDF";
import { printPlugin } from "@react-pdf-viewer/print";
import "@react-pdf-viewer/print/lib/styles/index.css";
import {
  CurrentPagePlugin,
  DownloadFilePlugin,
} from "../../../components/PDFView/Toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import HighlightedCSV from "./HighlightedCSV";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    minHeight: "100%",
  },
}));

function SourceDialog({ source, handleSourceChange }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { file_id } = source;
  const { files } = useSelector((state) => state.files);
  const file = files.find((f) => f["id"] === file_id);

  return (
    file && (
      <>
        {isXS ? (
          <Dialog open={true} fullScreen classes={{ paper: classes.dialog }}>
            <SourcePDF
              file={file}
              source={source}
              handleSourceChange={handleSourceChange}
            />
          </Dialog>
        ) : (
          <Grid
            item
            md={5}
            height={"100%"}
            // sx={{ paddingRight: 3, paddingBottom: 3 }}
          >
            <SourcePDF
              file={file}
              source={source}
              handleSourceChange={handleSourceChange}
            />
          </Grid>
        )}
      </>
    )
  );
}

export function SourcePDF({
  file,
  source,
  handleSourceChange,
  entity = false,
}) {
  const theme = useTheme();
  const [isFullViewDialogOpen, setIsFullViewDialogOpen] = useState(false);
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const zoomPluginInstance1 = zoomPlugin();
  const pageNavigationPluginInstance1 = pageNavigationPlugin();
  const zoomPluginInstance2 = zoomPlugin();
  const pageNavigationPluginInstance2 = pageNavigationPlugin();
  const printPluginInstance = printPlugin();
  const getFilePluginInstance = getFilePlugin();
  const [currentPage, setCurrentPage] = useState(null);

  const handleClose = () => {
    handleSourceChange(null);
  };

  const handleOpenDialog = () => {
    setIsFullViewDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsFullViewDialogOpen(false);
  };

  return (
    <>
      <Box
        height={"100%"}
        sx={{
          // borderRadius: 4,
          borderLeft: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
        }}>
        <Box
          px={2}
          py={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography variant="h6" noWrap>
            {file["name"]}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            {file["file_url"] && (
              <>
                <CurrentPagePlugin
                  pageNavigationPluginInstance={pageNavigationPluginInstance1}
                />
                <IconButton onClick={handleOpenDialog}>
                  <Fullscreen />
                </IconButton>
                {entity && (
                  <>
                    <IconButton onClick={() => printPluginInstance.print()}>
                      <Print />
                    </IconButton>
                    <DownloadFilePlugin
                      getFilePluginInstance={getFilePluginInstance}
                      color={theme.palette.text.secondary}
                    />
                  </>
                )}
                <Divider orientation="vertical" sx={{ mx: 1, height: 24 }} />
              </>
            )}
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box px={2} py={1}>
          <Typography variant="subtitle1" noWrap>
            {source["section"]}
          </Typography>
        </Box>
        <Divider />
        <Box
          px={1}
          bgcolor={"#525659"}
          sx={{
            flex: 1,
            overflow: "auto",
            cursor: "pointer",
            // borderBottomLeftRadius: 16,
            // borderBottomRightRadius: 16,
          }}>
          {file["file_url"] ? (
            <PDFView
              source={source}
              file={file}
              scale={isXS ? 0.5 : 0.8}
              zoomPluginInstance={zoomPluginInstance1}
              pageNavigationPluginInstance={pageNavigationPluginInstance1}
              printPluginInstance={printPluginInstance}
              getFilePluginInstance={getFilePluginInstance}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <Typography sx={{ color: "white" }}>
              Error while loading the document
            </Typography>
          )}
        </Box>
      </Box>
      {isFullViewDialogOpen && (
        <FullViewPDF
          currentPage={currentPage}
          source={source}
          zoomPluginInstance={zoomPluginInstance2}
          pageNavigationPluginInstance={pageNavigationPluginInstance2}
          file={file}
          printPluginInstance={printPluginInstance}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default SourceDialog;
