import axios, { getHeaders } from "./axios";
import {
  BOOKMARK_UNMARK_MESSAGE_URL,
  CREATE_SESSION_URL,
  DELETE_SESSION_URL,
  FETCH_IMAGE_URL,
  GET_BOOKMARKED_MESSAGES_URL,
  GET_PIPELINE_LOGS_URL,
  GET_PROMPT_QUESTIONS,
  GET_SESSIONS_URL,
  GET_SESSION_MESSAGES_URL,
  MESSAGE_FEEDBACK_URL,
  UPDATE_SESSION_URL,
} from "./url";

class SessionService {
  createSession = (accountId, domainId, chatMode, fileId) =>
    new Promise((resolve, reject) => {
      axios
        .post(CREATE_SESSION_URL, {
          account_id: accountId,
          domain_id: domainId,
          chat_mode: chatMode,
          file_id: fileId,
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updateSession = (conversation) =>
    new Promise((resolve, reject) => {
      axios
        .put(UPDATE_SESSION_URL.format(conversation["id"]), conversation)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  deleteSession = (conversationId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(DELETE_SESSION_URL.format(conversationId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getAllSessions = (domainId, chatMode) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_SESSIONS_URL.format(domainId, chatMode))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getSessionMessages = (conversationId) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_SESSION_MESSAGES_URL.format(conversationId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  recordFeedback = (messageId, feedbackData) =>
    new Promise((resolve, reject) => {
      axios
        .post(MESSAGE_FEEDBACK_URL.format(messageId), feedbackData)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  deleteFeedback = (messageId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(MESSAGE_FEEDBACK_URL.format(messageId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getPromptQuestions = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_PROMPT_QUESTIONS)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getPipelineLogs = (questionId) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_PIPELINE_LOGS_URL.format(questionId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getImage = (imageURL) =>
    new Promise((resolve, reject) => {
      fetch(FETCH_IMAGE_URL.format(imageURL), { headers: getHeaders() })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  bookmarkOrUnmarkMessage = (message) =>
    new Promise((resolve, reject) => {
      axios
        .put(BOOKMARK_UNMARK_MESSAGE_URL.format(message["message_id"]), message)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getBookmarkedMessages = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_BOOKMARKED_MESSAGES_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const sessionService = new SessionService();

export default sessionService;
