import React from "react";
import { Box, Typography } from "@mui/material";

function NoBookmarks() {
  return (
    <Box
      flex={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      justifyContent={"center"}>
      <img src="/images/no_bookmarks.png" alt="no_bookmarks" width={150} />
      <Box mb={3} />
      <Typography variant="h6">No saved messages yet!</Typography>
      <Box mb={1} />
      <Typography>All your saved messages will appear here.</Typography>
    </Box>
  );
}

export default NoBookmarks;
