import React from "react";
import { Box, Button, Typography, IconButton, useTheme } from "@mui/material";
import Logo from "../../../components/Logo";
import {
  DescriptionRounded,
  MenuRounded,
  SearchRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useHistory } from "react-router-dom";
import GlobalSearchBar from "./GlobalSearchBar";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.light,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function Topbar({ toggleExpand, expanded }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const handleClickFiles = () => {
    history.push("/files");
  };

  return (
    <Box
      px={2}
      py={1}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <Box display={"flex"} alignItems={"center"}>
        <IconButton onClick={toggleExpand}>
          <MenuRounded />
        </IconButton>
        <Box display={"flex"} alignItems={"center"} ml={2}>
          <Logo variant="small" />
          <Box mr={1} />
          <Typography fontSize={15} fontWeight={600}>
            <span className={classes.gradientText}>Raven AI</span>
          </Typography>
        </Box>
      </Box>
      <GlobalSearchBar />
      <Button
        onClick={handleClickFiles}
        variant="outlined"
        startIcon={
          <DescriptionRounded sx={{ color: theme.palette.action.active }} />
        }
        sx={{
          color: theme.palette.text.primary,
          borderRadius: "50px",
          borderColor: theme.palette.divider,
          "&:hover": {
            borderColor: theme.palette.divider,
          },
        }}>
        Files
      </Button>
    </Box>
  );
}

export default Topbar;
