import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Tooltip,
  Chip,
  IconButton,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";
import { ArrowForwardIosRounded, GridOnRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
  icon: {
    margin: "0px 5px 0px -6px !important",
  },
  card: {
    // boxShadow: "0px 0px 4px 1.194px rgba(0, 0, 0, 0.12)",
    border: `1px solid ${theme.palette.divider}`,
    // background: "white",
    borderRadius: 12,
    margin: "16px 2px 2px 2px",
    // display: "inline-block",
  },
  activeCard: {
    border: `1px solid ${theme.palette.accent}`,
    background: theme.palette.paper,
  },
}));

function Source({ source, answer, handleSourceChange }) {
  const { classes } = useStyles();
  const { completedFiles } = useSelector((state) => state.files);
  const citations = answer["content_object"]["citations"] || [];

  if (citations.length === 0) {
    return null;
  }

  const citation = citations[0];
  const { section, file_id } = citation;
  const file = completedFiles.find((f) => f["id"] === file_id);
  const tabularData = JSON.parse(answer["content_object"]["text"]);

  const handleClickSource = () => {
    handleSourceChange({
      ...citation,
      csv_file_map: { [tabularData["sheet"]]: tabularData["csv_file_path"] },
      selected_row_ids: tabularData["selected_row_ids"],
      columns: tabularData["columns"],
      created_at: answer["created_at"],
      jsonQueryObject: tabularData["json_query_object"],
    });
  };

  const isSelected = source && source["created_at"] === answer["created_at"];

  return (
    <Card
      className={`${classes.card} ${isSelected ? classes.activeCard : null}`}
      elevation={1}
      onClick={() => handleClickSource()}>
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            mr={1.5}
            width={"calc(100% - 36px)"}>
            <IconButton sx={{ background: " rgba(14, 157, 88, 0.08)" }}>
              <GridOnRounded sx={{ color: "#0E9D58" }} />
            </IconButton>
            <Box ml={1} width={"calc(100% - 48px)"}>
              <Typography fontWeight={500} variant="body2" noWrap>
                {section}
              </Typography>
              <Box mb={1.5} />
              {file && (
                <Tooltip title={tabularData["sheet"]}>
                  <Chip
                    label={
                      <Typography variant="caption" noWrap>
                        {tabularData["sheet"]}
                      </Typography>
                    }
                    size="small"
                    icon={
                      <img
                        src={getFileIconImage(file["file_type"])}
                        alt={file["name"]}
                        width={16}
                        height={16}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
          <IconButton size="small">
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Source;
