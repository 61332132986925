import React from "react";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { chatModes } from "../DashboardLayout/Sidebar/NewSessionButton";
import { GradientIconLight } from "../../components/GradientIcon";

const useStyles = makeStyles()((theme) => ({
  indicator: {
    background: theme.palette.light,
  },
  gradientText: {
    background: theme.palette.light,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function ChatModeTabs({ selectedChatMode, handleSelectedChatModeChange }) {
  const { classes } = useStyles();
  return (
    <Box>
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={selectedChatMode}
        onChange={(event, newValue) => {
          handleSelectedChatModeChange(newValue);
        }}
        variant="scrollable"
        scrollButtons="auto">
        {chatModes.map((chatMode) => {
          const { label, key, originalIcon } = chatMode;
          const Icon = originalIcon;
          return (
            <Tab
              key={key}
              value={key}
              icon={
                key === selectedChatMode ? (
                  <GradientIconLight
                    Icon={originalIcon}
                    sx={{ marginRight: 1 }}
                  />
                ) : (
                  <Icon />
                )
              }
              iconPosition="start"
              label={
                key === selectedChatMode ? (
                  <span className={classes.gradientText}>{label}</span>
                ) : (
                  label
                )
              }
              sx={{
                textTransform: "none",
                minHeight: 56,
              }}
            />
          );
        })}
      </Tabs>
      <Divider />
    </Box>
  );
}

export default ChatModeTabs;
