import React, { useState, useRef } from "react";
import { Button, Menu, Box, Divider } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import QueryBox from "../../Assistant/components/QueryBox/QueryBox";
import { useQueryHandler } from "../../../hooks/useQueryHandler";
import Question from "../../AskAI/components/Message/Question";
import Answer from "../../AskAI/components/Message/Answer";
import StreamMessage from "../../Assistant/components/StreamMessage";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Feedback from "../../AskAI/components/Feedback/Feedback";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function GlobalSearchBar() {
  const { classes } = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const handleMessageUpdate = (newMessage) => {
    setMessage(newMessage);
  };

  const { handleQueryIntent, tempQandA, isMetadataLoading } =
    useQueryHandler(handleMessageUpdate);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  const handleContinue = () => {
    history.push(`/assistant/${message["query"]["conversation_id"]}`);
    handleClose();
  };

  return (
    <>
      <Box mb={2} display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ padding: 1, minWidth: 36, width: 36 }}>
          <SearchRounded fontSize="small" />
        </Button>
      </Box>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={{
          paper: {
            sx: {
              maxWidth: 768,
              width: "100%",
              borderRadius: 4,
            },
          },
        }}>
        <Box>
          <Box px={2} pb={1}>
            <QueryBox
              handleQuery={handleQueryIntent}
              isStreaming={tempQandA}
              alwaysCreateNewSession={true}
            />
          </Box>
          {(message || tempQandA) && (
            <>
              <Divider />
              <Box p={2}>
                <Box maxHeight={350} overflow={"scroll"}>
                  {message && (
                    <Box>
                      <Question query={message["query"]} />
                      <Box mb={4} />
                      <Answer answer={message["answer"]} />
                    </Box>
                  )}
                  {tempQandA && (
                    <StreamMessage
                      isGlobalSearch={true}
                      tempQandA={tempQandA}
                      isMetadataLoading={isMetadataLoading}
                    />
                  )}
                </Box>
                {message && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    pl={6}
                    mt={2}>
                    <Feedback message={message} />
                    <Box display={"flex"} alignItems={"center"}>
                      <Button color="secondary" onClick={handleClose}>
                        <span className={classes.gradientText}>Cancel</span>
                      </Button>
                      <Box mr={1} />
                      <Button variant="contained" onClick={handleContinue}>
                        Continue Chat
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default GlobalSearchBar;
