import React, { useState, useEffect, useRef } from "react";
import { IconButton, Box } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import { debounce } from "lodash";
import { SCROLL_TO_BOTTOM } from "../../../utils/posthogEvents";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles()((theme, _params, classes) => ({
  scrollBottomBtn: {
    position: "absolute",
    bottom: 4,
    right: 0,
    zIndex: 999,
    borderRadius: 8,
    // transform: "translateX(-100%)",
    border: `1px solid ${theme.palette.divider}`,
    background: "rgba(255, 255, 255, 0.40)",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(4px)",
    // [theme.breakpoints.down("sm")]: {
    //   top: -40,
    //   right: -24,
    // },
  },
}));

function ScrollToBottom({ messagesRef, messagesEndRef }) {
  const history = useHistory();
  const location = useLocation();
  const posthog = usePostHog();
  const { classes } = useStyles();
  const [show, setShow] = useState(false);

  const debouncedOnSave = useRef(debounce(handleScroll, 500));

  function handleScroll() {
    if (messagesRef.current)
      setShow(
        messagesRef.current.scrollHeight - messagesRef.current.scrollTop >
          messagesRef.current.clientHeight + 1
      );
  }

  useEffect(() => {
    if (messagesRef.current) {
      handleScroll();
      messagesRef.current.addEventListener("scroll", debouncedOnSave.current);
    }
    return () => {
      if (messagesRef.current) {
        messagesRef.current.removeEventListener(
          "scroll",
          debouncedOnSave.current
        );
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      posthog.capture(SCROLL_TO_BOTTOM);
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      history.push(location.pathname);
    }
  };
  return (
    <Box width={"100%"} maxWidth={900} margin={"0 auto"} px={{ xs: 2, sm: 3 }}>
      <Box position={"relative"}>
        {show && (
          <IconButton
            size="small"
            className={classes.scrollBottomBtn}
            onClick={scrollToBottom}>
            <ExpandMoreRounded fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default ScrollToBottom;
