import React from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { CHECKLIST_DIAGRAM_MODE_URL } from "../../../../utils/routes";

function GoToMessage({ mode, sessionId, messageId }) {
  const theme = useTheme();
  const history = useHistory();

  const handleClickGoToMessage = () => {
    let url = `/qna/sessions/${sessionId}`;
    if (mode === "data_analysis") {
      url = `/data-analysis/sessions/${sessionId}`;
    } else if (mode === "checklist_diagram") {
      url = CHECKLIST_DIAGRAM_MODE_URL;
    }
    history.push({
      pathname: url,
      search: "?" + new URLSearchParams({ messageId }).toString(),
    });
  };

  return (
    <Tooltip title={"View original message"}>
      <IconButton
        size="small"
        sx={{ color: theme.palette.action.active, marginLeft: 1 }}
        onClick={handleClickGoToMessage}>
        <ArrowForwardRounded sx={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  );
}

export default GoToMessage;
