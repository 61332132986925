import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { getSessionItemDate } from "../../../utils/utils";
import { ArrowForwardIosRounded, MoreVertRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { EditRounded, DeleteRounded } from "@mui/icons-material";
import EditSession from "../../AskAI/components/EditSession";
import DeleteSession from "../../AskAI/components/DeleteSession";

const useStyles = makeStyles()((theme, _params, classes) => ({
  session: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
      [`& .${classes.actions}`]: {
        visibility: "visible",
      },
    },
  },
  actions: {
    visibility: "hidden",
    display: "flex",
    alignItems: "center",
    padding: 4,
    borderRadius: 4,
    background: "#424242",
  },
  actionIcon: {
    color: "white",
    cursor: "pointer",
  },
}));

function Session({ session, numOfItems, index, handleSessionClick }) {
  const { id, title, updated_at } = session;
  const isLast = index === numOfItems - 1;
  const isFirst = index === 0;
  const { classes } = useStyles();

  function getBorderRadius() {
    if (numOfItems === 1) {
      return "12px";
    }
    if (isFirst) {
      return "12px 12px 0px 0px";
    }
    if (isLast) {
      return "0px 0px 12px 12px";
    }
    return 0;
  }

  return (
    <Box>
      <Box
        sx={{
          borderRadius: getBorderRadius(),
        }}
        className={classes.session}
        onClick={() => handleSessionClick(id)}>
        <Box width={{ xs: "calc(100% - 64px)", sm: "100%" }}>
          <Typography fontWeight={500} noWrap>
            {title}
          </Typography>
          <Box mb={0.5} />
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="caption" color={"textSecondary"}>
              {getSessionItemDate(updated_at)}
            </Typography>
          </Box>
        </Box>
        <Box mr={3} />
        <SessionActionsMenu session={session} />
        <Box mr={3} />
        <ArrowForwardIosRounded fontSize="small" htmlColor="gray" />
      </Box>
      {!isLast && <Divider sx={{ ml: 2 }} />}
    </Box>
  );
}

function SessionActionsMenu({ session }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setOpen(false);
  };

  return (
    <>
      <Box
        ref={ref}
        className={classes.actions}
        sx={open ? { visibility: "visible !important" } : {}}
        onClick={(e) => e.stopPropagation()}>
        <MoreVertRounded
          className={classes.actionIcon}
          fontSize="small"
          onClick={handleOpen}
        />
      </Box>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        slotProps={{ paper: { sx: { borderRadius: 2, marginTop: 1 } } }}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <EditSession session={session} onClose={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <EditRounded />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </EditSession>
        <DeleteSession sessionId={session.id} onClose={handleClose}>
          <MenuItem sx={{ color: theme.palette.error.main }}>
            <ListItemIcon>
              <DeleteRounded sx={{ color: theme.palette.error.main }} />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </DeleteSession>
      </Menu>
    </>
  );
}

export default Session;
