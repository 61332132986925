import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { getAllFiles } from "../../core/repo/fileRepo";
import { getDomains } from "../../core/repo/domainRepo";
import { usePostHog } from "posthog-js/react";
import {
  getAccountDetails,
  getSelectedFilesLocalStorage,
  getUserDetails,
} from "../../core/storage/localStorage";
import { getUserAndAccount } from "../../core/repo/accountRepo";
import useIsMounted from "../../hooks/useIsMounted";
import {
  setSelectedFilesSuccess,
  getSessionsSuccess,
  getSessionMessagesSuccess,
} from "../../core/events/sessionEvents";
import SplashScreen from "../../components/SplashScreen";
import Topbar from "./Topbar/Topbar";

function DashboardLayout({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const account = getAccountDetails();
  const user = getUserDetails();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isMountedRef = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      dispatch(getSessionsSuccess([]));
      dispatch(getSessionMessagesSuccess([]));
      await dispatch(getUserAndAccount());
      await dispatch(getAllFiles());
      await dispatch(getDomains());
      dispatch(setSelectedFilesSuccess(getSelectedFilesLocalStorage()));
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    if (account && user)
      posthog.identify(account["name"], { email: user["email"] });
  }, [isMountedRef]);

  return isLoading ? (
    <SplashScreen />
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100vw"}
      sx={{ height: "100vh", height: "100svh" }}
      bgcolor={theme.palette.background.dark}>
      {/* <Topbar toggleExpand={toggleExpand} expanded={expanded} /> */}
      <Box flex={1} overflow={"hidden"} display={"flex"} flexDirection={"row"}>
        <Sidebar />
        <Box sx={{ flex: 1, overflow: "auto" }} p={1} pl={0}>
          <Box
            bgcolor={theme.palette.background.default}
            border={`1px solid ${theme.palette.divider}`}
            height={"100%"}
            width={"100%"}
            borderRadius={6}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
