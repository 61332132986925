import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Divider,
  Typography,
  IconButton,
  useTheme,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  DialogContent,
  Chip,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import CSVViewer from "../../../Files/ViewFile/CSVViewer";
import {
  CloseRounded,
  FullscreenRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ChevronRightRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { operatorSymbol } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
  icon: {
    margin: "0px 5px 0px -6px !important",
  },
  indicator: {
    background: theme.palette.light,
  },
  gradientText: {
    background: theme.palette.light,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  deleteIcon: {
    color: `${theme.palette.text.secondary} !important`,
  },
}));

function SourceDialog({ isAnswerLoading, fileId, source, handleSourceChange }) {
  const theme = useTheme();

  return (
    <Grid
      item
      md={8}
      height={"100%"}
      // sx={{ paddingRight: 3, paddingBottom: 3 }}
    >
      <Box
        height={"100%"}
        sx={{
          // borderRadius: 4,
          borderLeft: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}>
        {isAnswerLoading ? (
          <span
            className="loadingStyle"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              fontSize: 50,
            }}></span>
        ) : (
          <>
            {source["mainFile"] ? (
              <MainFileView
                source={source}
                handleSourceChange={handleSourceChange}
              />
            ) : (
              <GeneratedFileView
                fileId={fileId}
                source={source}
                handleSourceChange={handleSourceChange}
              />
            )}
          </>
        )}
      </Box>
    </Grid>
  );
}

function Sheets({ sheets, handleSourceChange, fileId }) {
  const { completedFiles } = useSelector((state) => state.files);
  const file = completedFiles.find((f) => f["id"] === fileId);
  return (
    <Box px={2} py={1} display={"flex"} flexWrap={"wrap"} gap={1}>
      {sheets.map((sheet, i) => {
        return (
          <Chip
            clickable
            onClick={() =>
              handleSourceChange({
                section: file["name"],
                csv_file_map: file["csv_file_map"],
                mainFile: true,
                defaultSheet: sheet,
              })
            }
            size="small"
            label={sheet}
            key={i}
          />
        );
      })}
    </Box>
  );
}

function Filters({ jsonQueryObject }) {
  const filters =
    jsonQueryObject && jsonQueryObject["filters"]
      ? jsonQueryObject["filters"]
      : [];

  function getLabel(filter) {
    const { column, operator, value } = filter;
    if (operator === "in") {
      return `${column} = (${value.join(", ")})`;
    } else if (operator === "between") {
      return `${column} between (${value.join(", ")})`;
    }
    return `${column} ${operatorSymbol[operator]} ${value}`;
  }
  return (
    <Box px={2} py={1} display={"flex"} flexWrap={"wrap"} gap={1}>
      {filters.map((filter, i) => {
        const label = getLabel(filter);
        return <Chip size="small" label={label} key={i} />;
      })}
    </Box>
  );
}

function MainFileView({ source, handleSourceChange }) {
  const { classes } = useStyles();
  const { section, csv_file_map } = source;
  const csvList = Object.keys(csv_file_map);
  const [selectedCSV, setSelectedCSV] = useState(
    source["defaultSheet"] || csvList[0]
  );

  const handleChangeCSV = (csv) => {
    setSelectedCSV(csv);
  };

  return (
    <>
      <Box>
        <Box
          px={2}
          py={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography noWrap>{section}</Typography>
            <Box mr={1} />
            <Chip
              label="Main File"
              sx={{ color: "white", background: "#616161" }}
            />
          </Box>
          <IconButton
            onClick={() => handleSourceChange(null)}
            sx={{ marginLeft: 1 }}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Tabs
          classes={{ indicator: classes.indicator }}
          value={selectedCSV}
          onChange={(event, newValue) => {
            handleChangeCSV(newValue);
          }}
          variant="scrollable"
          scrollButtons="auto">
          {csvList.map((sheet) => {
            return (
              <Tab
                key={sheet}
                value={sheet}
                label={
                  sheet === selectedCSV ? (
                    <span className={classes.gradientText}>{sheet}</span>
                  ) : (
                    sheet
                  )
                }
                sx={{
                  textTransform: "none",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Divider />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <CSVViewer file={{ file_url: csv_file_map[selectedCSV] }} />
      </Box>
    </>
  );
}

function GeneratedFileView({ fileId, source, handleSourceChange }) {
  const { completedFiles } = useSelector((state) => state.files);
  const file = completedFiles.find((f) => f["id"] === fileId);
  const { classes } = useStyles();
  const { csv_file_map } = source;
  const csvList = Object.keys(csv_file_map);
  const [areFiltersOpen, setAreFiltersOpen] = useState(true);
  const [areSourcesOpen, setAreSourcesOpen] = useState(false);
  const highlightColumns = source["columns"]
    ? source["columns"].map((column) => column.trim().toLowerCase())
    : [];
  const highlightRows = source["selected_row_ids"]
    ? source["selected_row_ids"].map((row) => row.toString())
    : [];

  const toggleFilters = () => {
    if (areSourcesOpen) {
      setAreSourcesOpen(false);
    }
    setAreFiltersOpen((prev) => !prev);
  };

  // const toggleSources = () => {
  //   if (areFiltersOpen) {
  //     setAreFiltersOpen(false);
  //   }
  //   setAreSourcesOpen((prev) => !prev);
  // };
  return (
    <>
      <Box>
        <Box
          px={2}
          py={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography noWrap>{source["section"]}</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="caption" color={"textSecondary"}>
                Source:
              </Typography>
              <Tooltip title={csvList[0]} arrow>
                <Chip
                  variant="outlined"
                  classes={{ deleteIcon: classes.deleteIcon }}
                  sx={{
                    maxWidth: 300,
                    mx: 1,
                  }}
                  clickable
                  onClick={() =>
                    handleSourceChange({
                      section: file["name"],
                      csv_file_map: file["csv_file_map"],
                      mainFile: true,
                    })
                  }
                  label={
                    <Typography variant="caption" noWrap>
                      {csvList[0]}
                    </Typography>
                  }
                  icon={
                    <img
                      src={getFileIconImage(file["file_type"])}
                      alt={csvList[0]}
                      width={16}
                      height={16}
                    />
                  }
                  onDelete={() =>
                    handleSourceChange({
                      section: file["name"],
                      csv_file_map: file["csv_file_map"],
                      mainFile: true,
                    })
                  }
                  deleteIcon={<ChevronRightRounded />}
                />
              </Tooltip>
            </Box>
            {source["jsonQueryObject"] &&
              source["jsonQueryObject"]["filters"] &&
              source["jsonQueryObject"]["filters"].length > 0 && (
                <Chip
                  classes={{ icon: classes.icon }}
                  label={"Filters"}
                  icon={
                    areFiltersOpen ? (
                      <ExpandLessRounded />
                    ) : (
                      <ExpandMoreRounded />
                    )
                  }
                  onClick={toggleFilters}
                  sx={{
                    background: areFiltersOpen ? "#E8EAF6" : "transparent",
                    flexDirection: "row-reverse",
                    fontWeight: 500,
                    marginLeft: 1,
                  }}
                />
              )}
            <IconButton
              onClick={() => handleSourceChange(null)}
              sx={{ marginLeft: 1 }}>
              <CloseRounded />
            </IconButton>
          </Box>
        </Box>
        {areFiltersOpen && (
          <Filters jsonQueryObject={source["jsonQueryObject"]} />
        )}
        {/* {areSourcesOpen && (
          <Sheets
            sheets={csvList}
            fileId={fileId}
            handleSourceChange={handleSourceChange}
          />
        )} */}
      </Box>
      <Divider />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <CSVViewer
          file={{ file_url: csv_file_map[csvList[0]] }}
          highlightColumns={highlightColumns}
          highlightRows={highlightRows}
        />
      </Box>
    </>
  );
}

export function SheetSelection({
  mode = "dark",
  csvList,
  selectedCSV,
  handleChangeCSV,
}) {
  return (
    csvList.length > 1 && (
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel
          id="sheet-select-label"
          sx={mode === "light" ? { color: "white !important" } : {}}>
          Select Sheet
        </InputLabel>
        <Select
          size="small"
          labelId="sheet-select-label"
          label="Select Sheet"
          value={selectedCSV}
          onChange={(e) => handleChangeCSV(e.target.value)}
          sx={
            mode === "light"
              ? {
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }
              : {}
          }>
          {csvList.map((sheetName) => (
            <MenuItem key={sheetName} value={sheetName}>
              {sheetName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}

export default SourceDialog;
