import React, { useEffect, useState } from "react";
import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import Account from "./Account";
import Header from "./Header";
import SelectDomain from "./SelectDomain";
import NewSessionButton from "./NewSessionButton";
import NavItems from "./NavItems";
import { makeStyles } from "tss-react/mui";
import { getAccountDetails } from "../../../core/storage/localStorage";

const useStyles = makeStyles()((theme, { expanded }, classes) => ({
  root: {
    width: expanded ? 190 : 72,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 8,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

function Sidebar() {
  const [expanded, setExpanded] = useState(false);
  const { classes } = useStyles({ expanded });

  return (
    <Box className={classes.root}>
      <Header expanded={expanded} toggleExpand={setExpanded} />
      <Box mb={1} />
      {/* {account["is_org"] && <SelectDomain expanded={expanded} />}
      <NewSessionButton expanded={expanded} /> */}
      {/* <Divider sx={{ mt: 2, mb: 1 }} /> */}
      <NavItems expanded={expanded} />
      <Account expanded={expanded} />
    </Box>
  );
}

export default Sidebar;
