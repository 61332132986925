import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAssistantModeSuccess } from "../../../../core/events/accountEvents";
import { Box, Typography, Switch } from "@mui/material";

function ProModeSwitch() {
  const dispatch = useDispatch();
  const { assistantMode } = useSelector((state) => state.account);

  const handleAssistantModeChange = (e) => {
    dispatch(toggleAssistantModeSuccess(e.target.checked));
  };

  return (
    <Box display={"flex"} alignItems={"center"} ml={1}>
      <Typography fontSize={13} fontWeight={500} color={"text.secondary"}>
        Pro Mode
      </Typography>
      <Box mr={1} />
      <Switch
        size="small"
        checked={assistantMode}
        onChange={handleAssistantModeChange}
      />
    </Box>
  );
}

export default ProModeSwitch;
