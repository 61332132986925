import React, { useRef, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import QueryBox from "./components/QueryBox/QueryBox";
import { makeStyles } from "tss-react/mui";
import Page from "../../components/Page";
import ScrollToBottom from "./components/ScrollToBottom";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSessionMessages } from "../../core/repo/sessionRepo";
import { formQuestionAnswerPairs } from "../AskAI/Messages";
import StreamMessage from "./components/StreamMessage";
import Results from "../AskAI/Messages/Results";
import SourceDialog from "../AskAI/components/SourceDialog";
import EntityDialog from "../AskAI/ChecklistDiagram/components/EntityDialog";
import TabularDialog from "../AskAI/DataAnalysis/Messages/SourceDialog";
import { useQueryHandler } from "../../hooks/useQueryHandler";
import Loader from "../../components/Loader";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
  },
}));

function Assistant() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const messagesRef = useRef();
  const messagesEndRef = useRef();
  const messageRefs = useRef({});
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryBoxContainerRef = useRef();
  const [queryContainerHeight, setQueryContainerHeight] = useState(0);
  const [source, setSource] = useState(null);
  const [entity, setEntity] = useState(null);
  const [tabular, setTabular] = useState(null);
  const [areMessagesFetched, setAreMessagesFetched] = useState(false);
  const { sessionId } = useParams();
  const messageId = new URLSearchParams(location.search).get("messageId");

  const handleMessageUpdate = (newMessage) => {
    setMessages((prev) => [...prev, newMessage]);
  };

  const { handleQueryIntent, tempQandA, isMetadataLoading } = useQueryHandler(
    handleMessageUpdate,
    sessionId
  );

  const handleQuery = (
    query,
    isNewSession = true,
    taggedFiles = [],
    taggedAgent = null,
    chatAgent = null
  ) => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);
    handleQueryIntent(query, isNewSession, taggedFiles, taggedAgent, chatAgent);
  };

  async function fetchMessages() {
    setIsLoading(true);
    try {
      const response = await dispatch(getSessionMessages(sessionId));
      const processedMessages = formQuestionAnswerPairs(
        response["messages"] || []
      );
      setMessages(processedMessages);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    setAreMessagesFetched(true);
  }

  useEffect(() => {
    fetchMessages();
  }, [sessionId]);

  //code to get the height of the query container which is used to decide the height of the last message
  useEffect(() => {
    const queryContainer = queryBoxContainerRef.current;

    if (queryContainer) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          setQueryContainerHeight(newHeight);
        }
      });

      resizeObserver.observe(queryContainer);

      return () => {
        resizeObserver.unobserve(queryContainer);
      };
    }
  }, []);

  useEffect(() => {
    areMessagesFetched && handleScroll();
  }, [areMessagesFetched]);

  function handleScroll() {
    if (messageId && messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
      });
    } else if (messagesEndRef.current) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  const handleSourceChange = (source) => {
    setSource(source);
    setEntity(null);
    setTabular(null);
  };

  const handleEntityChange = (entity) => {
    setEntity(entity);
    setSource(null);
    setTabular(null);
  };

  const handleTabularChange = (tabular) => {
    setTabular(tabular);
    setSource(null);
    setEntity(null);
  };

  return (
    <Page className={classes.root} title={"Messages"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Box flex={1} overflow={"auto"}>
          <Grid container height={"100%"}>
            <Grid
              item
              md={source ? 7 : entity || tabular ? 4 : 12}
              height={"100%"}
              width={"100%"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
                position={"relative"}>
                <Box
                  position={"absolute"}
                  top={0}
                  left={0}
                  right={0}
                  height={48}
                  zIndex={1}
                  sx={{
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)",
                  }}
                />
                <Box ref={messagesRef} flex={1} overflow={"auto"}>
                  <Box
                    // px={{ xs: 2, sm: 3 }}
                    pb={{ xs: 4, sm: 6 }}
                    pt={{ xs: 2, sm: 3 }}
                    // maxWidth={900}
                    // margin={"0 auto"}
                  >
                    <Results
                      isStreaming={tempQandA}
                      messages={messages}
                      messageRefs={messageRefs}
                      messagesRef={messagesRef}
                      handleSourceChange={handleSourceChange}
                      handleEntityChange={handleEntityChange}
                      handleTabularChange={handleTabularChange}
                      queryContainerHeight={queryContainerHeight}
                    />
                    {tempQandA && (
                      <StreamMessage
                        numOfMessages={messages.length}
                        tempQandA={tempQandA}
                        isMetadataLoading={isMetadataLoading}
                      />
                    )}
                    <Box ref={messagesEndRef} />
                  </Box>
                </Box>
                <ScrollToBottom
                  messagesRef={messagesRef}
                  messagesEndRef={messagesEndRef}
                />
                <Box
                  width={"100%"}
                  maxWidth={900}
                  margin={"0 auto"}
                  position={"relative"}
                  px={{ xs: 2, sm: 3 }}
                  // pt={{ xs: 1, sm: 3 }}
                  pb={{ xs: 1, sm: 3 }}>
                  <Box
                    position={"absolute"}
                    top={-24}
                    left={0}
                    right={0}
                    height={24}
                    zIndex={1}
                    sx={{
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)",
                    }}
                  />
                  <Box mb={"1px"} />
                  <QueryBox
                    queryContainerHeight={queryContainerHeight}
                    isStreaming={tempQandA}
                    handleQuery={handleQuery}
                    queryBoxContainerRef={queryBoxContainerRef}
                  />
                </Box>
              </Box>
            </Grid>
            {source && (
              <SourceDialog
                source={source}
                handleSourceChange={handleSourceChange}
              />
            )}
            {entity && (
              <EntityDialog
                entity={entity}
                handleEntityChange={handleEntityChange}
              />
            )}
            {tabular && (
              <TabularDialog
                fileId={tabular["file_id"]}
                source={tabular}
                handleSourceChange={handleTabularChange}
              />
            )}
          </Grid>
        </Box>
        {isLoading && <Loader />}
      </Box>
    </Page>
  );
}

export default Assistant;
