import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { chatAgents } from "../../../Assistant/components/QueryBox/NewSessionDialog";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  iconButton: {
    height: 36,
    width: 36,
    border: `1px solid ${theme.palette.divider}`,
    cursor: "default",
    background: "white",
    marginRight: 16,
    "&:hover": {
      background: "white",
    },
  },
}));

function AnswerAvatar({ answerType = "qna" }) {
  const { classes } = useStyles();
  const chatAgentKey = getChatAgentKey();
  const chatAgent = chatAgents.find((agent) => agent.key === chatAgentKey);

  function getChatAgentKey() {
    if (answerType === "entity") {
      return "checklist_search";
    } else if (answerType === "tabular_data") {
      return "data_analysis";
    } else if (answerType === "pnid") {
      return "pid_assist";
    }
    return "qna";
  }

  return (
    <Tooltip title={chatAgent.name}>
      <IconButton className={classes.iconButton}>
        <chatAgent.icon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default AnswerAvatar;
