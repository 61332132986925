import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSessions } from "../../core/repo/sessionRepo";
import { makeStyles } from "tss-react/mui";
import Page from "../../components/Page";
import Header from "./Header";
import Filters from "./Filters";
import Results from "./Results/Results";
import Loader from "../../components/Loader";
import useScrollHeader from "../../hooks/useScrollHeader";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function History() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { containerRef, showHeader } = useScrollHeader();
  const { sessions, isSessionsLoading } = useSelector((state) => state.session);
  const [filteredSessions, setFilteredSessions] = useState([]);

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    setFilteredSessions(sessions);
  }, [sessions]);

  const fetchConversations = async () => {
    try {
      await dispatch(getAllSessions());
    } catch (err) {
      console.log(err);
    }
  };

  const handleSessionClick = (id) => {
    history.push({
      pathname: `/assistant/${id}`,
    });
  };

  const handleQueryChange = (query) => {
    let filteredSessions = sessions;
    if (query) {
      filteredSessions = filteredSessions.filter((s) =>
        s["title"].toLowerCase().includes(query.toLowerCase())
      );
    }
    setFilteredSessions(filteredSessions);
  };

  return (
    <Page className={classes.root} title={"History"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header showHeader={showHeader} />
        <Box ref={containerRef} flex={1} overflow={"auto"}>
          <Box
            maxWidth={900}
            margin={"0 auto"}
            p={{ xs: 2, md: 3 }}
            pb={{ xs: 4, sm: 6 }}>
            <Box mb={7}>
              <Typography variant="h4">
                <span className={classes.gradientText}>History</span>
              </Typography>
            </Box>
            <Box>
              {sessions.length > 0 && (
                <Filters
                  sessions={filteredSessions}
                  handleQueryChange={handleQueryChange}
                />
              )}
              <Results
                sessions={filteredSessions}
                handleSessionClick={handleSessionClick}
              />
            </Box>
          </Box>
          {isSessionsLoading && <Loader />}
        </Box>
      </Box>
    </Page>
  );
}

export default History;
