export const FILES_URL = "/files";
export const SESSIONS_URL = "/sessions";
export const AUTHENTICATE_URL = "/authenticate";
export const ACCOUNT_KEY_URL = "/account-key";
export const LOGIN_URL = "/login";
export const ERROR_URL = "/404";
export const EMAIL_VERIFICATION_URL = "/email-verification";
export const ASSISTANT_URL = "/assistant";
export const Q_A_MODE_URL = "/qna/sessions";
export const CHECKLIST_DIAGRAM_MODE_URL = "/checklist-diagram/messages";
export const DATA_ANALYSIS_MODE_URL = "/data-analysis/sessions";
export const BOOKMARKS_URL = "/saved";
export const HISTORY_URL = "/history";
