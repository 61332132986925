import axios from "axios";
import { getAccountDetails, getUserDetails } from "../storage/localStorage";

const user = getUserDetails();
const account = getAccountDetails();
const instance = axios.create();
instance.defaults.headers.common["x-session-jwt"] = user
  ? user["session_jwt"] || null
  : null;
instance.defaults.headers.common["x-user-id"] = user ? user["id"] : null;
instance.defaults.headers.common["x-account-id"] = account
  ? account["id"]
  : null;
export default instance;

export const vanillaInstance = axios.create();

export function getHeaders() {
  return {
    "x-account-id": account["id"],
    "x-user-id": user["id"],
    ...(user["session_jwt"] && {
      "x-session-jwt": user["session_jwt"],
    }),
  };
}
