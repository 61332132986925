import React, { useState } from "react";
import { Box, Divider, Skeleton, Typography, useTheme } from "@mui/material";
import { AutoModeRounded } from "@mui/icons-material";
import { GradientIconAccent } from "../../../components/GradientIcon";
import Question from "../../AskAI/components/Message/Question";
import StreamAnswer from "../../AskAI/components/Message/StreamAnswer";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  dividerWrapper: {
    padding: 0,
  },
}));

function StreamMessage({
  isGlobalSearch = false,
  numOfMessages = 0,
  tempQandA = null,
  isMetadataLoading = false,
}) {
  const { classes } = useStyles();
  const [chatAgent, setChatAgent] = useState(
    tempQandA ? tempQandA["chatAgent"] : "qna"
  );
  const theme = useTheme();
  function getLoaderMessage() {
    switch (tempQandA["chatAgent"]) {
      case "checklist_search": {
        return "Finding the entity";
      }

      case "data_analysis": {
        return "Analyzing";
      }

      case "qna": {
        return "Retreiving Answer";
      }

      default: {
        return "Retreiving Answer";
      }
    }
  }

  return (
    <Box
      maxWidth={900}
      margin={"0 auto"}
      px={isGlobalSearch ? 0 : { xs: 2, sm: 3 }}
      sx={
        !isGlobalSearch
          ? {
              minHeight: `calc(100vh - ${numOfMessages !== 0 ? 250 : 280}px)`,
            }
          : {}
      }>
      {numOfMessages !== 0 && !isGlobalSearch && (
        <Divider sx={{ marginY: 6, borderColor: "transparent" }} />
      )}
      {numOfMessages === 0 && !isGlobalSearch && (
        <Divider
          classes={{ wrapper: classes.dividerWrapper }}
          sx={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            component="span"
            sx={{
              display: "inline-block",
              width: 120,
              padding: "5px 10px",
              background: "white",
              border: `1px solid ${theme.palette.divider}`,
              color: theme.palette.text.secondary,
              borderRadius: "16px",
              textAlign: "center",
            }}>
            Today
          </Typography>
        </Divider>
      )}
      <Question query={{ content_object: { text: tempQandA["query"] } }} />
      <Box
        mt={4}
        display="flex"
        alignItems={tempQandA["response"] ? "start" : "center"}>
        <GradientIconAccent
          Icon={AutoModeRounded}
          className={"iconLoading"}
          sx={{ fontSize: 32 }}
        />
        <Box mr={2} />
        {tempQandA["response"] ? (
          <Box>
            <StreamAnswer
              answer={{ content_object: { text: tempQandA["response"] } }}
            />
          </Box>
        ) : (
          <Typography>
            {getLoaderMessage()}
            <span className="loadingStyle"></span>
          </Typography>
        )}
      </Box>
      {isMetadataLoading && !isGlobalSearch && (
        <Box
          sx={{ my: 4, pl: 6 }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Box>
            {(chatAgent === "qna" || chatAgent === "data_analysis") && (
              <Skeleton variant="rounded" width={180} height={24} />
            )}
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default StreamMessage;
