import React, { useState } from "react";
import { Box } from "@mui/material";
import EditSessionDialog from "../Messages/EditSessionDialog";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { updateSession } from "../../../core/repo/sessionRepo";

function EditSession({ session, onClose = () =>{}, children }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsEditDialogOpen(true);
  };

  const handleClose = () => {
    setIsEditDialogOpen(false);
  };

  async function handleUpdateSession(session) {
    try {
      await dispatch(updateSession(session));
      enqueueSnackbar("Session has been renamed", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to rename session", { variant: "error" });
    }
    onClose();
  }

  return (
    <Box>
      <Box onClick={handleOpen}>{children}</Box>
      {isEditDialogOpen && (
        <EditSessionDialog
          session={session}
          handleUpdateSession={handleUpdateSession}
          onClose={handleClose}
        />
      )}
    </Box>
  );
}

export default EditSession;
