import React, { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteSession } from "../../../core/repo/sessionRepo";
import { useHistory } from "react-router-dom";
import { SESSIONS_URL } from "../../../utils/routes";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

function DeleteSession({ sessionId, onClose = () => {}, children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setIsDeleteDialogOpen(false);
  };

  async function handleDeleteSession(sessionId) {
    try {
      await dispatch(deleteSession(sessionId));
      // history.push({ pathname: SESSIONS_URL });
      enqueueSnackbar("Session has been deleted", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to delete session", { variant: "error" });
    }
    onClose()
  }

  return (
    <Box>
      <Box onClick={handleOpen}>{children}</Box>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={"Delete Session"}
          content={`Do you really want to delete this chat? This will permanently delete the conversations and follow-ups.`}
          noLabel={"cancel"}
          yesLabel={"yes, delete"}
          onNo={handleClose}
          onYes={() => handleDeleteSession(sessionId)}
          isDelete={true}
        />
      )}
    </Box>
  );
}

export default DeleteSession;
