import React from "react";
import { Box } from "@mui/material";
import RenderMarkdown from "./RenderMarkdown";
import Source from "../../DataAnalysis/Messages/Source";

function TabularDataAnswer({ source, answer, handleSourceChange = () => {} }) {
  function isHTMLString(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1); // Check if any element node exists
  }

  const result = answer["content_object"]["tabular_data_result"]
    ? answer["content_object"]["tabular_data_result"]["result"]
    : "";
  const isResultHTMLString = isHTMLString(result);

  return (
    <Box sx={{ maxWidth: 804, width: "100%", overflow: "scroll" }}>
      {isResultHTMLString ? (
        <div
          dangerouslySetInnerHTML={{
            __html: result,
          }}></div>
      ) : (
        <RenderMarkdown message={result} />
      )}
      <Source
        source={source}
        answer={answer}
        handleSourceChange={handleSourceChange}
      />
    </Box>
  );
}

export default TabularDataAnswer;
