import { produce } from "immer";
import { TOGGLE_ASSISTANT_MODE_SUCCESS } from "../events/accountEvents";

const initialState = {
  assistantMode: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ASSISTANT_MODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.assistantMode = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
